<template>
  <b-container class="report-account-plan-2 bg-light page-wrapper">
    <div class="main-content-wrapper">
      <b-row class="mb-0 pb-0" no-gutters>
        <b-breadcrumb :items="breadcrumbItems" class="dark"></b-breadcrumb>
      </b-row>

      <b-row class="mb-0 pb-0" no-gutters>
        <h1 class="text-left">{{ $t(translationPath + 'title_assets') }}</h1>
      </b-row>
      <account-plan-submenu active="assets"></account-plan-submenu>

      <div class="wrapper_white_with_border py-4">

        <account-plan-assets-header></account-plan-assets-header>

        <b-card no-body class="white-tabs-card">
          <b-tabs card class="white-tabs" @activate-tab="onTabActivate">
            <AssetsMenu>
              <template slot="account-plan-assets-misc">
                <b-row no-gutters>
                  <b-col class="col-xl-8 col-md-10">
                    <div class="text-regular" v-html="$t(translationPath + 'asset.misc.description')"></div>
                  </b-col>
                </b-row>
                <b-table class="spirecta-simple-table account-table-misc-asset-settings income-accounts-table" show-empty
                         hover responsive striped
                         stacked="lg"
                         ref="TableMisc"
                         :sort-compare="sortCompare"
                         :items="accounts"
                         :fields="fieldValues"
                         :tbodyTrClass="trClass"
                         :busy="busy"
                         :filter="accountsFilter"
                         :filter-included-fields="['title','group_name']"
                         :emptyFilteredText ="$t('common.no_filter_result')"
                >
                  <template v-slot:table-busy>
                    <loader/>
                  </template>

                  <!-- CUSTOM SLOT: FILTER -->
                  <template slot="top-row">
                    <td colspan="9">
                      <div class="d-flex align-items-center">
                        <i class="fa fa-search text-secondary"></i>
                        <b-form-input v-model="accountsFilter" size="sm" :placeholder="$t('common.filter_placeholder')"/>
                      </div>
                    </td>
                  </template>
                  <!-- END CUSTOM SLOT: FILTER -->

                  <template v-slot:cell(group)="row">
                    <custom-select
                      v-model="row.item.group"
                      :options="accountGroups.asset"
                      label="title"
                      :placeholder="'Select account group'"
                      :search-placeholder="$t('common.type_to_filter').toString()"
                      :disabled="isFormDisabled"
                      @select="onChange"
                    ></custom-select>
                  </template>

                  <template v-slot:cell(is_importable)="row">
                    <b-form-select
                      v-model="row.item.is_importable"
                      :options="isImportableOptions"
                      :disabled="isFormDisabled"
                      @input="(value) => onIsImportableInput(row.item, value, true)"
                    ></b-form-select>
                  </template>

                  <template v-slot:cell(code)="row">
                    <b-form-input
                      class="input-code"
                      :class="row.item.is_invalid_code ? 'is-invalid' : ''"
                      v-model="row.item.code"
                      @input="(value) => onCodeInput(row.item, value)"
                    ></b-form-input>
                  </template>

                  <template v-slot:cell(title)="row">
                    <b-form-input
                      v-model="row.item.title"
                      :class="row.item.is_invalid_title ? 'is-invalid' : ''"
                      @input="(value) => onTitleInput(row.item, value)"
                    ></b-form-input>
                  </template>

                  <template v-slot:cell(importable_type)="row">
                    <b-form-select
                      v-model="row.item.importable_type"
                      :options="importableTypeOptions"
                      :disabled="!row.item.is_importable || isFormDisabled"
                      @input="onChange"
                    ></b-form-select>
                  </template>

                  <template v-slot:cell(allow_update)="row">
                    <b-form-select
                      v-model="row.item.allow_update"
                      :options="[{ value: 1, text: $t('common.yes') }, { value: 0, text: $t('common.no') }]"
                      @input="onChange"
                    ></b-form-select>
                  </template>

                  <template v-slot:cell(importable_default_parser)="row">
                    <b-form-select
                      v-model="row.item.importable_default_parser"
                      :options="parserOptions"
                      :disabled="!row.item.is_importable || isFormDisabled"
                      @input="onChange"
                    ></b-form-select>
                  </template>

                  <template v-slot:cell(actions)="row">
                    <button
                      class="btn plain-btn text-regular action-button"
                      @click="onDeleteAccount(row.item)"
                    >
                      <i class="flaticon solid trash-3 text-secondary" :title="$t('common.delete')"></i>
                    </button>
                  </template>
                </b-table>

                <b-row class="mb-5 mt-4">
                  <b-col class="pt-4 pt-md-1">
                    <b-button
                      type="submit"
                      variant="primary"
                      class="float-right ml-3 px-4 btn-save"
                      :disabled="saveDisabled || busy"
                      @click="onSave"
                    >{{ $t('common.save') }}
                    </b-button>
                  </b-col>
                </b-row>
              </template>
            </AssetsMenu>
          </b-tabs>
        </b-card>

      </div><!-- END: wrapper_white_with_border -->
    </div><!-- END: main-content-wrapper -->

    <confirm-modal
      ref="ConfirmModal"
      :title="$t('common.please_confirm').toString()"
      :message="$t('reports.other.account_plan.confirm_page_leave_message').toString()"
      variant="primary"
      @confirmed="onLeavePageConfirmed"
    ></confirm-modal>

    <delete-networth-account-modal ref="deleteModalNetworth"></delete-networth-account-modal>

    <delete-account-modal
      ref="deleteModal"
      :itemName="deleteAccountName"
      :itemType="deleteAccountType"
      :moveTransactions="onDeleteMoveTransactions"
      :iDestroyAccountId="iDestroyAccountId"
      @on-delete-confirm="deleteAccount"
      @hide="resetModal"
    ></delete-account-modal>
  </b-container>
</template>

<script>
import axios from 'axios'
import AccountPlanTabMixin from './AccountPlanTabMixin'
import AccountPlanActionsMixin from './AccountPlanActionsMixin'
import CustomSelect from '@/components/common/CustomSelect'
import _ from 'lodash'
import AssetsMenu from './submenu/AssetsMenu'
import AccountPlanAssetsHeader from './headers/AccountPlanAssetsHeader'

export default {
  name: 'AccountPlanAssetsMatchGlobals',
  components: { CustomSelect, AssetsMenu, AccountPlanAssetsHeader },
  mixins: [AccountPlanTabMixin, AccountPlanActionsMixin],
  data () {
    return {
      parsers: [],
      accounts: [],
      accountsFilter: ''
    }
  },
  computed: {
    local () {
      return this.$i18n.locale
    },
    fieldValues () {
      return [
        { key: 'title', label: this.$t('common.title'), sortable: true, class: 'td-title' },
        { key: 'code', label: this.$t(this.translationPath + 'table.th_code'), sortable: true, class: 'td-code' },
        { key: 'group', label: this.$t(this.translationPath + 'table.th_account_group'), sortable: true, class: 'td-account_group' },
        { key: 'is_importable', label: this.$t(this.translationPath + 'table.th_is_importable'), sortable: true, class: 'td-importable' },
        { key: 'importable_type', label: this.$t(this.translationPath + 'table.th_import_type'), sortable: true, class: 'td-import_type' },
        { key: 'importable_default_parser', label: this.$t(this.translationPath + 'table.th_default_parser'), sortable: true, class: 'td-default_parser' },
        { key: 'allow_update', label: this.$t(this.translationPath + 'table.th_allow_update'), sortable: false, class: 'td-allow_update' },
        { key: 'actions', label: '', class: 'td-actions' }
      ]
    },
    isImportableOptions () {
      return [
        { value: 1, text: this.$t('common.yes') },
        { value: 0, text: this.$t('common.no') }
      ]
    },
    importableTypeOptions () {
      return [
        {
          text: this.$t('assets_liabilities.create_account_wizard.assets.step3.is_importable_select_value.debit_card'),
          value: 'debit_card'
        },
        {
          text: this.$t('assets_liabilities.create_account_wizard.assets.step3.is_importable_select_value.bank_account'),
          value: 'bank_account'
        },
        {
          text: this.$t('assets_liabilities.create_account_wizard.assets.step3.is_importable_select_value.asset'),
          value: 'asset'
        },
        {
          text: this.$t('assets_liabilities.create_account_wizard.assets.step3.is_importable_select_value.other'),
          value: 'other'
        }
      ]
    },
    parserOptions () {
      const options = []
      this.parsers.map((item) => {
        options.push({ value: item.class_name, text: this.$te('common.' + item.parser_nice_name) ? this.$t('common.' + item.parser_nice_name) : item.parser_nice_name })
      })
      return options.sort((a, b) => a.text > b.text ? 1 : -1)
    }
  },
  methods: {
    async loadData () {
      if (!this.currentCOA.locale) {
        return true
      }

      this.busy = true
      Promise.all([
        this.loadAccountGroupsWithData('asset'),
        this.loadAccountGroups('asset'),
        this.loadParsers()
      ])
        .then(() => {
          this.busy = false
          this.makeRows()
        })
    },
    async loadParsers () {
      axios.get(`${process.env.VUE_APP_ROOT_API}/bankstatements/parsers`)
        .then(response => {
          const autoParser = {
            class_name: 'auto',
            parser_nice_name: 'bankparser.auto_parse',
            bank_name: 'Auto',
            description: 'auto',
            countries: [],
            login_url: 'auto',
            use_in_autoparse: true
          }
          this.parsers = [autoParser, ...response.data.data]
        })
        .catch(error => {
          const { status } = error
          console.error(error)
          if (status === 500) {
            this.$nextTick(function () {
              this.loadParsers()
            })
          }
        })
    },
    makeRows () {
      if (this.accountsGrouped.asset) {
        this.accounts = _.cloneDeep(this.accountsGrouped.asset).filter((item) => item.is_account).sort((a, b) => {
          const nameA = a.title
          const nameB = b.title
          if (this.locale) {
            return nameA.localeCompare(nameB, this.locale)
          } else {
            if (nameA > nameB) {
              return 1
            }
            if (nameA < nameB) {
              return -1
            }
            return 0
          }
        })

        this.accounts.map((account) => {
          account.is_invalid_title = false
          account.is_invalid_code = false
          account.group = null

          if (this.accountGroups.asset) {
            this.accountGroups.asset.map((group) => {
              if (account.group_id === group.id) {
                account.group = group
                account.group_name = group.title
              }
            })
          }
          this.onIsImportableInput(account, account.is_importable, false)
        })
      }
    },
    onSave () {
      this.saveDisabled = true

      const putData = {
        accounts: []
      }
      this.accounts.map((item) => {
        putData.accounts.push({
          id: item.id,
          group_id: item.group.id,
          is_importable: item.is_importable,
          importable_type: item.importable_type,
          importable_default_parser: item.importable_default_parser,
          title: item.title,
          code: item.code,
          allow_update: item.allow_update
        })
      })

      axios.put(`${process.env.VUE_APP_ROOT_API}/accounts/update-multiple`, putData)
        .then(response => {
          this.$bvToast.toast(this.$t(this.translationPath + 'alert_update_misc_success_description').toString(), {
            title: this.$t(this.translationPath + 'alert_update_misc_title').toString(),
            variant: 'success',
            solid: true,
            'auto-hide-delay': 3000
          })
          this.accounts.map((item) => {
            item.is_invalid_title = false
            item.is_invalid_code = false
          })
          this.$refs.TableMisc.refresh()
          this.hasUnsavedChanges = false
        })
        .catch((error) => {
          console.error(error)
          this.$bvToast.toast(this.$t(this.translationPath + 'alert_update_misc_fail_description').toString(), {
            title: this.$t(this.translationPath + 'alert_update_misc_title').toString(),
            variant: 'danger',
            solid: true,
            'auto-hide-delay': 3000
          })

          const response = error.response.data
          if (response.title) {
            response.title.map((id) => {
              this.accounts.map((item) => {
                if (item.id === id) {
                  item.is_invalid_title = true
                }
              })
            })
          }
          if (response.code) {
            response.code.map((id) => {
              this.accounts.map((item) => {
                if (item.id === id) {
                  item.is_invalid_code = true
                }
              })
            })
          }
          this.$refs.TableMisc.refresh()
        })
        .then(() => {
          this.saveDisabled = false
        })
    },
    onTitleInput (item, value) {
      item.is_invalid_title = value.length < 2
      this.hasUnsavedChanges = true
    },
    onCodeInput (item, value) {
      item.is_invalid_code = !(new RegExp(/^\d*$/)).test(value)
      this.hasUnsavedChanges = true
    },
    onIsImportableInput (item, value, isUserEdit) {
      if (!value) {
        item.disabled_importable_type = item.importable_type
        item.disabled_importable_default_parser = item.importable_default_parser
        item.importable_type = null
        item.importable_default_parser = null
      } else {
        if (item.disabled_importable_type) {
          item.importable_type = item.disabled_importable_type
        }
        if (item.disabled_importable_default_parser) {
          item.importable_default_parser = item.disabled_importable_default_parser
        }
      }
      if (isUserEdit) {
        // this.hasUnsavedChanges = true
        this.onChange()
      }
    }
  },
  created () {
    this.loadData()
  },
  watch: {
    currentCOA: {
      deep: true,
      handler () {
        this.loadData()
      }
    },
    accountGroups: {
      deep: true,
      handler () {
        if (!this.accountGroups.assets) {
          return false
        }

        this.accounts.map((account) => {
          this.accountGroups.asset.map((group) => {
            if (account.group_id === group.id) {
              account.group = group
            }
          })
          this.onIsImportableInput(account, account.is_importable, false)
        })
      }
    }
  }
}
</script>

<style lang="scss">
  @import './AccountPlan.scss';
</style>
